'use client'

import React from 'react'

import { motion } from 'framer-motion';

import './mainBlock.scss';
import { WavesButton } from '@/components/UI/Buttons/WavesButton/WavesButton';


const mainTextAnimation = {
    hidden: (custom: any) => ({
        opacity: 0,
        x: 100 * custom.arrow,
    }),
    visible: (custom: any) => ({
        x: 0,
        opacity: 1,
        transition: {
            delay: 0.1 * custom.offset,
            duration: 1,
            type: 'spring'
        }
    })
}

const MainBlock = () => {
  return (
    <div className="main-block" id="main-block-id">
          <motion.div
                className="banner-block"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                >
              <div className="banner-block__content">
                  <motion.p
                    variants={mainTextAnimation}
                    className="banner-text"
                    custom={{
                        arrow: -1,
                        offset: 1
                    }}
                    >Software Engineer</motion.p>
                  <motion.p
                    variants={mainTextAnimation}
                    custom={{
                            arrow: 1,
                            offset: 2
                        }}
                    className="banner-text__main"
                    ><span className="orange">Николай</span> Гусев</motion.p>
                  <motion.p
                    variants={mainTextAnimation}
                    custom={{
                        arrow: -1,
                        offset: 1
                    }}
                    className="quote">“Будет падать - будем думать!”</motion.p>
              </div>
              {/* banner-block__button */}
              <WavesButton text='Подробнее' />
          </motion.div>
        </div>
  )
}

export default MainBlock;
