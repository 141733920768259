'use client';
import React from 'react'
import { motion } from 'framer-motion';

import './skillChartNew.scss'

const data = [
    {name: 'C#', value: 75},
    {name: 'JavaScript', value: 85},
    {name: 'TypeScript', value: 70},
    {name: 'Python', value: 40}
]

const animationLine = {
    hidden: {
        width: 0
    },
    visible: (custom: number) => ({
        width: `${custom}%`,
        transition: {
            duration: 1
        }
    }),
}

const SkillChartNew = () => {
  return (
    <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ amount: 0.2, once: true }}
        className='skill-chart-new'
    >
        <div className="wrapper">
            {data.sort((a, b) => b.value - a.value).map((item, index) =>
                <div className="wrapper__item" key={index}>
                    <div className="item__text">
                        <p>{ item.value }%</p>
                        <h3>{ item.name }</h3>
                    </div>
                    <div className="item__line" >
                        <motion.span
                        variants={animationLine} custom={item.value} style={{ width: `${ item.value }%` }} ></motion.span>
                    </div>
                </div>
            )}
        </div>
    </motion.div>
  )
}

export default SkillChartNew;
