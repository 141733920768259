'use client';
import React from 'react'

import './faq.scss';
import { BaseIcon } from '../UI/Icons/BaseIcon/BaseIcon';

import vkImage from "../../../public/img/vk-2.svg";
import telegramImage from "../../../public/img/telegram.svg";
import { Link } from 'react-scroll';
import { motion } from 'framer-motion';
import Image from "next/image";

const animation = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        y: 0,
    },
}

const leftanimationQ = {
    hidden: (custom: any) => ({
        opacity: 0,
        x: 100 * custom.arrow,
    }),
    visible: (custom: any) => ({
        x: 0,
        opacity: 1,
        transition: {
            delay: 0.07 * custom.offset,
            duration: 1,
            type: 'spring'
        }
    })
}

const FAQ = () => {
  return (
    <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ amount: 0.2, once: true }}
        className="qblock"
        variants={animation}
        id="faq">
            <div className="qblock-title__block wow zoomInDown">
                <p className="qblock-title__block-subtitle"><span className="orange">Николай</span> Гусев</p>
                <p className="qblock-title__block-title">Ответы на вопросы</p>
                <Image src="https://cdn.nikolaygusev.ru/landing/img/FAQ/underline.svg" width={189} height={4} alt="underline" />
            </div>
            <div className="qblock-content wow slideInLeft">
                <motion.div
                initial="hidden"
                whileInView="visible"
                variants={leftanimationQ}
                viewport={{ once: true }}
                custom={{
                    arrow: -1,
                    offset: 1
                }}
                className="qblock-content__top">
                    <div className="qblock-content__answer orange-block">
                        <p>Стек технологий, которым я владею довольно обширный, поэтому вынес его в отдельный раздел.
                            Ознакомиться с ним вы можете 👉
                            <Link style={{ textDecoration: 'underline', cursor: 'pointer' }} to='skill-bages' duration={500} offset={-73} smooth={true}>тут</Link>!
                            </p>
                    </div>
                    <div className="qblock-content-stage">
                        <img src="https://cdn.nikolaygusev.ru/landing/img/FAQ/line.svg" alt="" className="line" />
                        <div className="qblock-content-stage__step orange">
                            <p>01</p>
                        </div>
                        <img src="https://cdn.nikolaygusev.ru/landing/img/FAQ/line.svg" alt="" className="line" />
                    </div>
                    <div className="qblock-content__q">
                        <p>Каким стеком технологий владеете?</p>
                    </div>
                </motion.div>
                <motion.div
                initial="hidden"
                whileInView="visible"
                variants={leftanimationQ}
                viewport={{ amount: 0.2, once: true }}
                custom={{
                    arrow: 1,
                    offset: 2
                }}
                className="qblock-content__middle">
                    <div className="qblock-content__q">
                        <p>Используете CMS-системы для разрарботки  (WordPress, Tilda и др.)?</p>
                    </div>
                    <div className="qblock-content-stage">
                        <img src="https://cdn.nikolaygusev.ru/landing/img/FAQ/line.svg" alt="" className="line" />
                        <div className="qblock-content-stage__step">
                            <p>02</p>
                        </div>
                        <img src="https://cdn.nikolaygusev.ru/landing/img/FAQ/line.svg" alt="" className="line" />
                    </div>
                    <div className="qblock-content__answer">
                        <p>Нет, я не использую <b>No-Code решений ❌</b>.
                            Разработка ведется исключительно на языках программирования и соответствующих фреймворках,
                            которые позволяют решить поставленные задачи.</p>
                        <p> Данный подход позволяет создавать уникальный дизайн и функционал,
                            а также быть спокойным за оптимизацию и защищенность ваших приложений.✅</p>
                    </div>
                </motion.div>
                <motion.div
                    initial="hidden"
                    whileInView="visible"
                    variants={leftanimationQ}
                    custom={{
                        arrow: -1,
                        offset: 3
                    }}
                    viewport={{ amount: 0.2, once: true }}
                    className="qblock-content__bottom">
                    <div className="qblock-content__answer">
                        <p>На данный момент все зависит от моей загруженности в других проектах,
                            но вы всегда можете написать мне в
                            <a href="https://vk.com/niikolay.gusev" target='blank' className="line-link">
                                <BaseIcon src={vkImage} alt={'vk_link'} />
                            </a>
                             или
                             <a href="https://t.me/gusev_niiikolay" target='blank' className="line-link">
                                <BaseIcon src={telegramImage} alt={'telegram_link'} />
                            </a>
                            для обсуждения вашего заказа.</p>
                        <p>Стоимость разработки рассчитывается в индивидуальном порядка для каждого заказчика.</p>
                    </div>
                    <div className="qblock-content-stage">
                        <img src="https://cdn.nikolaygusev.ru/landing/img/FAQ/line.svg" alt="" className="line" />
                        <div className="qblock-content-stage__step">
                            <p>03</p>
                        </div>
                        <img src="https://cdn.nikolaygusev.ru/landing/img/FAQ/line.svg" alt="" className="line" />
                    </div>
                    <div className="qblock-content__q">
                        <p>Берете ли вы заказы на разработку? Если да, то сколько это стоит?</p>
                    </div>
                </motion.div>
            </div>
        </motion.div>
  )
}

export default FAQ;
