'use client'
import React from 'react'

import './wavesButton.scss'
import { Link } from 'react-scroll'

interface IWavesButtonProps {
    text: string
}

export const WavesButton: React.FC<IWavesButtonProps> = ({ text }) => {
  return (
    <Link smooth={true}  to='about-block' duration={1000} className={`btn-waves`} href="#">
        <span className='btn-waves__text'>{ text }</span>
        <span className='btn-waves__gr'></span>
    </Link>
  )
}
