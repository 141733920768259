'use client';

import React from 'react'

import './statisticBar.scss';

import { motion } from 'framer-motion';

const animation = {
    hidden: {
        opacity: 0,
        y: -50
    },
    visible: (custom: number) => ({
        opacity: 1,
        y: 0,
        transition: {
            delay: custom * 0.2
        }
    }),
}

const StatisticBar = () => {
  return (
    <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ amount: 0.2, once: true }}
        className="statistic-wrapper statistic-wrapper_new"
    >
          <div className="static-block wow jackInTheBox">
              <div className="static-block__content">
                  <motion.div className="static-block__content-item" variants={animation} custom={1}>
                      <p className="value-item">3</p>
                      <p>крупных проектов</p>
                  </motion.div>
                  <motion.div className="static-block__content-item" variants={animation} custom={2}>
                      <p className="value-item">10+</p>
                      <p>довольных клиентов</p>
                  </motion.div>
                  <motion.div className="static-block__content-item" variants={animation} custom={3}>
                      <p className="value-item">50 000+</p>
                      <p>строчек кода написано</p>
                  </motion.div>
                  <motion.div className="static-block__content-item" variants={animation} custom={4}>
                      <p className="value-item">3+</p>
                      <p>года работы в сфере IT</p>
                  </motion.div>
              </div>
          </div>
        </motion.div>
  )
}

export default StatisticBar;
