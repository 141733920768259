'use client';

import React from 'react'

import './sparklingButton.scss'

interface ISparklingButtonProps {
  text: string,
  disabled?: boolean,
  onClick?: (event: any) => void,
  isLoading?: boolean
}

export const SparklingButton: React.FC<ISparklingButtonProps> = ({ text, onClick, disabled, isLoading }) => {
  const onClickHandler = (event: any) => {
    if(!isLoading && onClick) {
      onClick(event)
    }
  }
  return (
      <button onClick={onClickHandler} disabled={disabled} className={`bubbly-button ${isLoading ? 'loading' : ''}`}>
        {!isLoading ? text :
            <>
              <span className="loader"></span>
            </>
        }
      </button>
  )
}
