'use client';
import React from 'react'

import './contactBlock.scss';
import { SparklingButton } from '../UI/Buttons/SparklingButton/SparklingButton';
import { SubmitHandler, useForm } from 'react-hook-form';
import { api } from '@/api/axios';
import {Bounce, toast} from "react-toastify";
import {AxiosResponse} from "axios";

interface IFeedbackFromData {
    fullName: string,
    userLink: string,
    message: string,
}

const ContactBlock = () => {
    const {register,
        handleSubmit,
        formState: { errors},
        reset,
        clearErrors,
        watch} = useForm<IFeedbackFromData>({
        defaultValues: {
            message: ''
        }
    })
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const watchMessage = watch('message');

    const toastType = {
        "true": toast.success,
        "false": toast.error
    }

    const notify = (message: string, status: "true" | "false") => toastType[status](message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
    });

    const submit: SubmitHandler<IFeedbackFromData> = async (payload) => {
        setIsLoading(true);
        if (!isLoading) {
            console.log('Отправка')
            await sendMessage(payload).then((response: AxiosResponse) => {
                const { data } = response;
                if(data.status) {
                    reset();
                    clearErrors();
                }
                notify(data.message, data.status);
            });
        }
    }

    const sendMessage = async (data: IFeedbackFromData) => {
        const response = await api.post('/send-question', data);

        setIsLoading(false);

        return response;
    }

    const clearTrim = (event: any) => {
        event.target.value = event.target.value.trimStart();
    }

  return (
    <div className="contact-block" id="contact">
            <div className="contact-block__left">
                <div className="contact-block__left-feedback">
                    <div className="contact-block__left-feedback__title-block wow fadeInBottomLeft">
                        <p className="feedback__subtitle">Я ГОТОВ ВАМ ПОМОЧЬ!</p>
                        <p className="feedback__title">ЗАДАЙТЕ СВОЙ ВОПРОС</p>
                    </div>
                    <form
                        id="clientForm"
                        className="contact-block__left-feedback__content-block"
                        onSubmit={handleSubmit(submit)}
                        autoComplete="off"
                        >
                        <div className={`feedback__item ${ errors.fullName ? 'error-item' : '' }`}>
                            <input
                                type="text"
                                className="feedback__input"
                                {...register('fullName', { required: true })}
                                required/>
                            <span className="feedback-label">ФИО</span>
                        </div>
                        <div className={`feedback__item ${ errors.userLink ? 'error-item' : '' }`}>
                            <input
                                type="text"
                                className="feedback__input"
                                {...register('userLink', { required: true })}
                                required
                            />
                            <span className="feedback-label">Как с вами связаться?</span>
                        </div>
                        <div className={`feedback__item ${ errors.message ? 'error-item' : '' }`}>
                            <div className="feedback__input-message">
                                {/* <label htmlFor="feedback-message">Сообщение</label> */}
                                <span>{watchMessage?.trim().length} / 1000</span>
                                <textarea
                                    {...register('message',
                                        {
                                            required: "Поле обязательно для заполнения.",
                                            minLength: {
                                                value: 10,
                                                message: "Минимальное длина сообщения - 10",
                                            },
                                            maxLength: {
                                                value: 1000,
                                                message: "Максимальная длина сообщения - 1000"
                                            },
                                            onChange: clearTrim
                                        }
                                    )}
                                    className="feedback__input"
                                    id="feedback-message"
                                    placeholder="Введите сообщение..."></textarea>
                                {
                                    <p
                                        className="error-text"
                                        style={{visibility: errors?.message ? "visible" : "hidden"}}
                                    >
                                        {errors?.message?.message || 'Errors'}
                                    </p>
                                }
                            </div>
                        </div>
                        <div className="feedback-footer">
                            <SparklingButton isLoading={isLoading} text={'Отправить'} />
                        </div>
                    </form>
                </div>
            </div>
        </div>
  )
}

export default ContactBlock;
